@import "~bootstrap/scss/bootstrap";

.bg-flying-books {
    background-image: url('../../assets/images/flying-books.svg');
    // background-size: 100% 100%;
    background-position: center -90px;
    background-repeat: no-repeat;
}

.auth {
    // min-height: 100vh;
    .auth-container {
        display: flex;
        .auth-banner {
            width: 40%;
        }
        .auth-form {
            width: 60%;
            max-width: 655px;
            margin-bottom: 150px;
        }
    }
    .auth-card {
        padding: 50px 65px 30px 65px;
        background: #FFFFFF;
        border-radius: 20px;
    }
    .auth-subline {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #141A46;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .auth-options {
        display: flex;
        .auth-option {
            width: 163px;
            height: 53px;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            margin-right: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:active {
                color: #FFFFFF;
            }
        }
        .btn-signup-facebook {
            background: #141A46;
            &:active {
                background: #141A46;
            }
        }
        .btn-signup-google {
            background: #ABACB8;
            &:active {
                background: #ABACB8;
            }
        }
        .btn-signup-email {
            background: #DB805E;
            &:active {
                background: #DB805E;
            }
        }
    }
    .auth-link {
        color: #E1805E;
        text-decoration: none;
        cursor: pointer;
    }
    .terms-line {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #ABACB8;
    }
    .form-wrapper {
        .form-group {
            margin-bottom: 20px;
            label {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                color: #141A46;
                margin-bottom: 10px
            }
            .form-control-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                .form-control {
                    height: 53px;
                    background: #FFFFFF;
                    box-shadow: inset 0px 2px 6px #D2D2D2;
                    display: flex;
                    align-items: center;
                    padding-left: 29px;
                    padding-right: 42px;
                    border-radius: 0px;
                    &:hover, &:focus, &:active, &:focus-visible, &:focus-within {
                        outline: none;
                        border: none;
                    }
                    &[type=password] {
                        padding-right: 100px;
                    }
                }
                .form-check-icon {
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    background-image: url('../../assets/images/icons/form-check.svg');
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    right: 15px;
                }
                .v-dotted-line {
                    border-left: dotted 2px #D2D2D2;
                    width: 2px;
                    height: 65%;
                    position: absolute;
                    right: 55px;
                }
                .eye-icon {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/images/icons/ic-eye.svg');
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    right: 65px;
                }
                .toggle-show {
                    position: absolute;
                    right: 26px;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #141A46;
                }
            }
        }
    }
    .btn-continue {
        width: 150px;
        height: 53px;
        background: #DB805E;
        border-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
    }
    .btn-back {
        width: 133px;
        height: 53px;
        background: #FFFFFF;
        border: 2px solid #D9D9D9;
        border-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #141A46;
        text-align: center;
        margin-right: 20px;
    }
    .btn-register {
        width: 125px;
        height: 53px;
        background: #DB805E;
        border-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
    }
    .logo {
        max-width: 330px;
        width: 100%;
    }
    .start-options {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-start-writing {
            width: 192px;
            height: 53px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            &::before {
                content: '';
                position: relative;
                width: 26.77px;
                height: 12.04px;
                background-image: url('../../assets/images/icons/start-writing.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin-right: 4px;
            }
        }
        .btn-start-reading {
            margin-left: 20px;
            width: 214px;
            height: 53px;
            background: #FFFFFF;
            border: 2px solid #D9D9D9;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #141A46;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            &::before {
                content: '';
                position: relative;
                width: 42px;
                height: 25.98px;
                background-image: url('../../assets/images/icons/start-reading.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
            &:hover, &:focus, &:active, &:focus-within, &:focus-visible {
                background-color: #141A46;
                border-color: #141A46;
                color: #FFFFFF;
                outline: none;
                box-shadow: none;
                &::before {
                    filter: brightness(0) invert(1);;
                }
            }
        }
    }
    &.login {
        .auth-form {
            .form-control[name=password] {
                padding-right: 65px !important;
            }
            .eye-icon {
                right: 25px !important;
            }
        }
    }
}

@include media-breakpoint-down(lg) { // 992px
    .auth {
        .auth-options {
            display: flex;
            flex-direction: column;
            .auth-option {
                width: 100% !important;
                margin-top: 10px;
                &:first-child {
                    margin-top: 0px;
                }
            }
        }
        .start-options {
            display: flex;
            flex-direction: column;
            .btn-start-writing {
                width: 168px;
                height: 48px;
                font-weight: 600;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 10px;
                margin-left: auto;
                margin-right: auto;
            }
            .btn-start-reading {
                margin-left: auto;
                margin-right: auto;
                width: 168px;
                height: 48px;
                font-weight: 600;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 20px;
                &::before {
                    margin-right: 8px;
                }
            }
        }
    }
}
@include media-breakpoint-down(md) { // 768px
    .auth {
        .auth-container {
            display: flex;
            .auth-banner {
                width: 40%;
                display: none;
            }
            .auth-form {
                width: 100%;
                max-width: 655px;
            }
        }
        .btn-continue {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .auth {
        .auth-card {
            padding: 30px;
        }
    }
}