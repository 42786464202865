@import "~bootstrap/scss/bootstrap";
.policy {
    .policy-content {
        padding-top: 28px;
        padding-bottom: 80px;
    }
    .policy-date {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        color: #8A8A8A;
    }
    .policy-link {
        display: block;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-decoration-line: underline;
        color: #353535;
    }
    h5 {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #141A46;
    }
    p {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #141A46;
    }
}

@include media-breakpoint-down(md) { // 768px
    .policy {
        .policy-content {
            padding-top: 14px;
            padding-bottom: 70px;
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .policy {
        .policy-content {
            padding-top: 0px;
            padding-bottom: 60px;
        }
        .policy-date {
            font-size: 16px;
            line-height: 32px;
        }
        .policy-link {
            font-size: 14px;
            line-height: 20px;
        }
        h5 {
            font-size: 18px;
            line-height: 26px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}