@import "~bootstrap/scss/bootstrap";

.home {
    .section-hero {
        h1 {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 600;
            font-size: 46px;
            line-height: 55px;
            color: #141A46;
            width: max-content;
            overflow: visible;
            position: relative;
            z-index: 1;
        }
        p {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #141A46;
            margin-bottom: 50px;
        }
        h6 {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 40px;
            color: #141A46;
            margin-bottom: 14px;
        }
        .btn-start-writing {
            width: 192px;
            height: 48px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            margin-bottom: 70px;
            &::before {
                content: '';
                position: relative;
                width: 26.77px;
                height: 12.04px;
                background-image: url('../../assets/images/icons/start-writing.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin-right: 4px;
            }
        }
        .btn-start-reading {
            margin-left: 20px;
            width: 214px;
            height: 48px;
            background: #FFFFFF;
            border: 2px solid #D9D9D9;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #141A46;
            display: flex;
            align-items: center;
            margin-bottom: 70px;
            &::before {
                content: '';
                position: relative;
                width: 42px;
                height: 25.98px;
                background-image: url('../../assets/images/icons/start-reading.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                margin-right: 10px;
            }
            &:hover, &:focus, &:active, &:focus-within, &:focus-visible {
                background-color: #141A46;
                border-color: #141A46;
                color: #FFFFFF;
                outline: none;
                box-shadow: none;
                &::before {
                    filter: brightness(0) invert(1);;
                }
            }
        }
        @include media-breakpoint-down(xl) {
            h1 {
                width: auto;
            }
        }
        @include media-breakpoint-down(lg) {
            h1 {
                font-weight: 600;
                font-size: 35px;
                line-height: 40px;
                min-width: 270px;
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                min-width: 258px;
            }
            h6 {
                font-weight: 700;
                font-size: 18px;
                line-height: 40px;
            }
            .btn-start-writing, .btn-start-reading {
                margin-bottom: 44px;
            }
        }
        @include media-breakpoint-down(md) {
            .col-order-1 {
                order: 1;
            }
            .col-order-2 {
                order: 2;
            }
            h1 {
                font-weight: 600;
                font-size: 25px;
                margin-top: 20px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 25px;
            }
            h6 {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
            }
            .btn-start-writing {
                width: 158px;
                height: 48px;
                font-weight: 600;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 20px;
            }
            .btn-start-reading {
                margin-left: 8px;
                width: 168px;
                height: 48px;
                font-weight: 600;
                font-size: 14px;
                line-height: 30px;
                margin-bottom: 20px;
                &::before {
                    margin-right: 8px;
                }
            }
        }
    }
    
    .section-banner {
        background-color: rgba(#E5E1D6, 0.3);
        padding: 50px;
        .has-divider {
            border-left: 2px dotted #ABACB8;
            border-right: 2px dotted #ABACB8;
        }
        p {
            padding: 0 30px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #141A46;
        }
        @include media-breakpoint-down(lg) {
            padding: 33px;
            .has-divider {
                border-top: 2px dotted #ABACB8;
                border-bottom: 2px dotted #ABACB8;
                border-left: none;
                border-right: none;
            }
            p {
                padding: 20px 0px;
                margin: 0 -15px;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
            }
        }
        @include media-breakpoint-down(md) {
            padding: 7px 20px 20px;
            .has-divider {
                border-top: 2px dotted #ABACB8;
                border-bottom: 2px dotted #ABACB8;
                border-left: none;
                border-right: none;
            }
            p {
                padding: 20px 0px;
                margin: 0 -15px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    
    .section-join {
        padding: 60px 0;
        .img-group {
            position: relative;
            padding-bottom: 40%;
            .img-stack {
                position: relative;
                z-index: 1;
            }
            .img-poem {
                position: absolute;
                top: 0px;
                left: 0px;
                transform: translateY(20%);
            }
        }
        p {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 36px;
            color: #141A46;
            margin-bottom: 40px;
        }
        @include media-breakpoint-down(lg) {
            padding: 20px 0;
            .img-group {
                padding-bottom: 0px;
            }
            p {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
            }
        }
        @include media-breakpoint-down(md) {
            padding: 0;
            .img-group {
                padding-bottom: 0px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
    
    .section-feature {
        // background: rgba(171, 172, 184, 0.3);
        background: rgba(#ABACB8, 0.15);
        padding: 80px;
        
        h1 {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 600;
            font-size: 46px;
            line-height: 50px;
            text-align: center;
            color: #141A46;
            margin-bottom: 30px;
        }
        .feature-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: max-content;
            margin: auto;
            .feature-icon {
                max-width: 180px;
                border-radius: 50%;
                background-color: #ABACB8;
                overflow: hidden;
                transition: background-color 0.15s;
                img {
                    width: 100%;
                }
            }
            .feature-text {
                margin-top: 8px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 38px;
                color: #ABACB8;
                transition: color 0.15s;
            }
            &.active, &:hover {
                .feature-icon {
                    background-color: #E1805E;
                    transition: background-color 0.15s;
                }
                .feature-text {
                    color: #000000;
                    transition: color 0.15s;
                }
            }
        }
        .divider {
            margin: 35px;
            border-top: 2px dotted #ABACB8;
        }
        h2 {
            font-family: 'Lora';
            font-style: italic;
            font-weight: 600;
            font-size: 34px;
            line-height: 38px;
            text-align: center;
            color: #141A46;
            margin-bottom: 40px;
        }
        .feature-details, .feature-details.d-none {
            transition: display 0.15s;
        }
        .story-item {
            text-align: center;
            max-width: 300px;
            margin: auto;
            h4 {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: #141A46;
            }
            p {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: #141A46;
            }
        }
        @include media-breakpoint-down(lg) {
            padding: 60px 40px;
            h1 {
                font-weight: 600;
                font-size: 35px;
                line-height: 30px;
            }
            .feature-item {
                .feature-icon {
                    max-width: 156px;
                }
                .feature-text {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 38px;
                }
            }
            .divider {
                margin: 30px 10px;
                border-top: 2px dotted #ABACB8;
            }
            h2 {
                font-weight: 600;
                font-size: 24px;
                line-height: 38px;
                margin-bottom: 30px;
            }
            .story-item {
                max-width: 100%;
                h4 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 26px;
                }
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding: 34px 0px;
            h1 {
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
            }
            .feature-item {
                .feature-icon {
                    max-width: 103px;
                }
                .feature-text {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 38px;
                }
            }
            .divider {
                margin: 15px 10px;
                border-top: 2px dotted #ABACB8;
            }
            h2 {
                font-weight: 600;
                font-size: 18px;
                line-height: 38px;
                margin-bottom: 23px;
            }
            .story-item {
                max-width: 100%;
                h4 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
    
    .section-testimonials {
        padding: 80px 0;
        h1 {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 600;
            font-size: 46px;
            line-height: 50px;
            color: #141A46;
        }
        .quote {
            display: flex;
            margin-top: 70px;
            &::before {
                content: '“';
                height: 49px;
                font-family: 'PT Serif';
                font-style: normal;
                font-weight: 400;
                font-size: 150px;
                line-height: 40px;
                color: #E1805E;
                position: relative;
                top: 45px;
                margin-right: 8px;
            }
            p {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 42px;
                color: #141A46;
                transition: display 0.35s;
                strong {
                    font-weight: 700;
                }
            }
            .btn-read-more {
                width: 150px;
                height: 48px;
                background: transparent;
                border: none;
                outline: none;
                box-shadow: none;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 36px;
                color: #141A46;
                padding: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                &:hover, &:focus, &:active {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
                &::after {
                    content: "";
                    display: block;
                    width: 45px;
                    height: 45px;
                    background-image: url('../../assets/images/icons/sort-down.svg');
                    background-size: 100% 100%;
                    transform: rotate(0deg);
                }
    
                &.less {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            padding: 45px 0;
            h1 {
                font-weight: 600;
                font-size: 35px;
                line-height: 50px;
            }
            .quote {
                margin-top: 25px;
                &::before {
                    font-weight: 400;
                    font-size: 100px;
                    line-height: 40px;
                    top: 25px;
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                }
                .btn-read-more {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 36px;
                    &::after {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding: 37px 0;
            h1 {
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
            }
            .quote {
                margin-top: 25px;
                &::before {
                    font-weight: 400;
                    font-size: 100px;
                    line-height: 40px;
                    top: 25px;
                }
                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                }
                .btn-read-more {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 36px;
                    &::after {
                        width: 33px;
                        height: 33px;
                    }
                }
            }
        }
    }
}