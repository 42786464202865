@import "~bootstrap/scss/bootstrap";

.section-story {
    .story-container {
        padding-top: 70px;
        padding-bottom: 80px;
    }
    .chapter-dropdown {
        position: relative;
        .dropdown-toggle {
            width: 180px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 42px;
            color: #ABACB8;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::after {
                content: "";
                width: 20px;
                height: 22px;
                background-image: url('../../assets/images/icons/arrow-down.svg');
                background-size: 100% 100%;
                border: none;
            }
        }
        .dropdown-menu {
            top: 20px !important;
            min-width: auto;
            width: 180px;
            background: #FFFFFF;
            box-shadow: -4px 4px 6px rgba(197, 197, 197, 0.5);
            border-radius: 0px;
            padding: 19px 41px 19px 26px !important;
            border: none;
            z-index: 90;
            &::before {
                content: "";
                position: absolute;
                width: 73px;
                height: 3px;
                border-top: 3px solid #E1805E;
                top: -5px;
                left: 0px;
            }
            .dropdown-item {
                padding: 2px 0px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #141A46;
                &:hover {
                    background-color: transparent;
                    font-weight: bold;
                }
                &.active {
                    background: transparent;
                    font-weight: bold;
                }
            }
            .dropdown-divider {
                border-top: 2px dotted #ABACB8;
            }
        }
    }
    .featured-img-wrapper {
        display: flex;
        align-items: center;
    }
    .featured-img {
        width: 100%;
        overflow: hidden;
        margin: 35px 0 45px 0;
        img {
            width: 100%;
            max-height: 800px;
            object-fit: cover;
        }
    }
    .blog-head, .blog-tail {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #141A46;
    }
    .blog-content {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        color: #141A46;
    }
}

@include media-breakpoint-down(xl) { // 1200px
    
    .section-story {
        .story-container {
            padding-top: 60px;
            padding-bottom: 70px;
        }
        .chapter-dropdown {
            position: relative;
            .dropdown-toggle {
                width: 150px;
                font-size: 16px;
                line-height: 24px;
                &::after {
                    content: "";
                    width: 16px;
                    height: 18px;
                }
            }
            .dropdown-menu {
                top: 20px !important;
                width: 150px;
                padding: 15px 35px 15px 20px !important;
                &::before {
                    content: "";
                    position: absolute;
                    width: 73px;
                    height: 3px;
                    border-top: 3px solid #E1805E;
                    top: -5px;
                    left: 0px;
                }
                .dropdown-item {
                    padding: 2px 0px;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #141A46;
                    &:hover {
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
                .dropdown-divider {
                    border-top: 2px dotted #ABACB8;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) { // 992px
    .section-story {
        .chapter-dropdown {
            position: relative;
            .dropdown-toggle {
                width: 130px;
                font-size: 14px;
                line-height: 24px;
                &::after {
                    content: "";
                    width: 14px;
                    height: 16px;
                }
            }
            .dropdown-menu {
                top: 20px !important;
                width: 130px;
                padding: 15px 25px 15px 18px !important;
                &::before {
                    content: "";
                    position: absolute;
                    width: 73px;
                    height: 3px;
                    border-top: 3px solid #E1805E;
                    top: -5px;
                    left: 0px;
                }
                .dropdown-item {
                    padding: 2px 0px;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #141A46;
                    &:hover {
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
                .dropdown-divider {
                    border-top: 2px dotted #ABACB8;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) { // 768px
    .section-story {
        .story-container {
            padding-top: 50px;
            padding-bottom: 60px;
            .chapter-dropdown {
                .dropdown-menu {
                    top: 10px !important;
                }
            }
            .featured-img {
                margin: 20px 0 30px 0;
            }
            .blog-head, .blog-tail {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: #141A46;
            }
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .section-story {
        .story-container {
            padding-top: 30px;
            padding-bottom: 40px;
            .chapter-dropdown {
                position: relative;
                margin-top: 10px;
                .dropdown-toggle {
                    width: 120px;
                    font-size: 13px;
                    line-height: 20px;
                    &::after {
                        content: "";
                        width: 13px;
                        height: 15px;
                    }
                }
                .dropdown-menu {
                    top: 7px !important;
                    width: 120px;
                    padding: 15px 20px 15px 15px !important;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 73px;
                        height: 3px;
                        border-top: 3px solid #E1805E;
                        top: -5px;
                        left: 0px;
                    }
                    .dropdown-item {
                        padding: 2px 0px;
                        font-family: 'Libre Franklin';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 15px;
                        color: #141A46;
                        &:hover {
                            background-color: transparent;
                            font-weight: bold;
                        }
                    }
                    .dropdown-divider {
                        border-top: 2px dotted #ABACB8;
                    }
                }
            }
            .featured-img {
                margin: 10px 0 30px 0;
            }
            .blog-head, .blog-tail {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: #141A46;
            }
        }
    }
}