@import "~bootstrap/scss/bootstrap";
.headline {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 55px;
    color: #141A46;
}

.subline {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    color: #141A46;
}

.dropdown-divider {
    height: 1px;
    border-top-width: 1px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-color: var(--bs-dropdown-divider-bg);;
    &.dotted {
        border-style: dotted;
    }
}

.profile-dropdown {
    position: relative;
    width: 135px;
    margin-left: 16px;
    z-index: 600;
    .dropdown-toggle {
        position: relative;
        background-color: transparent !important;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        margin: auto;
        z-index: 999;
        &::after {
            display: none;
        }
        &:hover, &:focus, &:focus-visible, &:active {
            background-color: transparent !important;
        }
        .avatar {
            position: relative;
            width: 56px;
            height: 56px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            .badge {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -1px;
                left: -15px;
                &.badge-orange {
                    background: #E1805E;
                    color: #FFFFFF;
                }
            }
        }
    }
    .dropdown-menu {
        position: absolute;
        top: 28px !important;
        min-width: auto !important;
        background: #141A46;
        border-radius: 40px 8px 0px 0px;
        padding: 55px 15px;
        z-index: 900;
        width: 135px;
        left: 50% !important;
        transform: translateX(-50%);
        .dropdown-item {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #FFFFFF;
            padding: 2px 0px;
            &:hover, &:active, &:focus, &:focus-visible {
                background-color: transparent;
                border: none;
                outline: none;
                box-shadow: none;
                font-weight: bold;
            }
        }
        .dropdown-divider {
            width: 100%;
            height: 2px;
            border-top: 2px dotted #ABACB8;
        }
    }
}

.profile-banner {
    position: relative;
    background: rgba(171, 172, 184, 0.15);
    .btn-profile-edit {
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 112px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        color: #141A46;
    }
    .author-profile {
        display: flex;
        align-items: center;
        .author-img {
            width: 179px;
            height: 179px;
            overflow: hidden;
            margin-top: 28px;
            margin-bottom: 28px;
            margin-right: 42px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .btn-upload-icon {
                position: absolute;
                bottom: 0px;
                right: 0px;
                border-radius: 50%;
                background: #D9D9D9;
                &:hover {
                    background: #ABACB8;
                }
            }
        }
        .author-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .author-name {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 28px;
                color: #141A46;
            }
            .author-follows {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #141A46;
            }
        }
    }
    .follow-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .socials {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .social-link {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn-follow {
                margin-left: 22px;
                width: 112px;
                height: 53px;
                background: #DB805E;
                border-radius: 8px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .btn-follow {
            margin-left: 22px;
            width: 112px;
            height: 53px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.co-author-profile-banner {
    background: rgba(229, 225, 214, 0.3);
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .author-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: calc(100% - 80px);
        .author-img {
            width: 109px;
            height: 109px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .author-info {
            margin-top: 8px;
            .author-name {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 1.3;
                color: #141A46;
                margin-bottom: 0px;
            }
            .author-follows {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.3;
                color: #141A46;
            }
        }
        .btn-follow {
            margin-top: 8px;
            width: 98px;
            height: 53px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
        }
        .co-authors {
            margin-top: 10px;
            .co-author-label {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
                color: #141A46;
            }
            .co-author-name {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #141A46;
                a {
                    color: #141A46;
                    text-decoration: none;
                }
            }
        }
        .socials {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .social-link {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                }
            }
        }
    }
}

.story-banner {
    position: relative;    
    .banner-wrapper {
        padding-top: 50px;
        padding-bottom: 65px;
        .img-poem {
            position: absolute;
            bottom: 0px;
            right: 0px;
            max-height: 100%;
        }
        .img-hand-book {
            position: absolute;
            bottom: 0px;
            right: 112px;
            max-height: 90%;
        }
        .filter-form {
            background: rgba(171, 172, 184, 0.15);
            padding: 25px 10px;
            border-radius: 12px;
            .title {
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                color: #141A46;
                padding-bottom: 10px;
            }
            .filter-name {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 42px;
                color: #141A46;
                display: flex;
                align-items: center;
            }
            .filter-item {
                display: flex;
                align-items: center;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #141A46;
                padding: 5px 3px;
                .item-checkbox {
                    position: relative;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    padding-left: 26px;
                    display: flex;
                    align-items: center;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                        &:checked ~ .checkmark::after {
                            display: block;
                        }
                    }
                    .checkmark {
                        position: absolute;
                        left: 0;
                        height: 18px;
                        width: 18px;
                        background-image: url(../../assets/images/components/Checkbox-outline.svg);
                        background-repeat: no-repeat;
                        background-position: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &::after {
                            content: "";
                            background-image: url(../../assets/images/components/Checkbox-check.svg);
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 11px;
                            height: 8px;
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.story-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
        background: #FFFFFF;
        border: 2px solid #D9D9D9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        padding: 7px 21px;
        margin: 0 22px;
        &:hover, &:focus, &:active, &:focus-visible {
            background-color: #141a46;
            border-color: #141a46;
            box-shadow: none;
            outline: none;
            color: #fff;
        }
    }
}

.story-template {
    .btn-story-edit {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        background: rgba(#abacb8, 0.5);
        &:hover, &:active {
            outline: none;
            background: rgba(#abacb8, 1);
        }
        img {
            width: calc(100% - 14px);
        }
    }
}

.reading-progress-bar {
    display: none;
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 4px;
    .current-progress-bar {
        width: 0%;
        height: 100%;
        background-color: #DB805E;
    }
}

.scrolled {
    .reading-progress-bar {
        display: block;
    }
}
.scrolled + .write-navbar {
    .reading-progress-bar {
        display: block;
    }
}

@include media-breakpoint-down(xl) { // 1200px
    .profile-banner {   
        .author-profile {
            .author-img {
                width: 159px;
                height: 159px;
            }
            .author-info {
                .author-name {
                    font-size: 28px;
                }
            }
        }
    }
    .story-banner {
        .banner-wrapper {
            padding-top: 40px;
            padding-bottom: 55px;
        }
    }
}

@include media-breakpoint-down(lg) { // 992px
    .headline {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 40px;
        color: #141A46;
    }
    .subline {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #141A46;
    }
    .profile-dropdown {
        width: auto;
        margin-left: auto;
        margin-right: 30px;
        .dropdown-toggle {
            .avatar {
                width: 48px;
                height: 48px;
            }
        }
        .dropdown-menu {
            width: 130px;
            left: 50% !important;
            transform: translateX(-50%);
            padding: 45px 15px;
            .dropdown-item {
                font-size: 14px;
            }
        }
    }
    .profile-banner {
        .btn-profile-edit {
            width: 95px;
            height: 35px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #141A46;
        }
        .author-profile {
            .author-img {
                width: 115px;
                height: 115px;
                margin-right: 24px;
            }
            .author-info {
                .author-name {
                    font-size: 18px;
                    line-height: 1.3;
                    margin-bottom: 0;
                }
                .author-follows {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
        }
        .follow-buttons {
            .btn-follow {
                width: 95px;
                height: 44px;
                margin-left: 18px;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
    .co-author-profile-banner {
        background: rgba(229, 225, 214, 0.3);
        width: 100%;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
        .author-profile {
            display: flex;
            align-items: center;
            justify-content: center;
            .author-img {
                width: 109px;
                height: 109px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .author-info {
                .author-name {
                    font-size: 18px;
                    line-height: 1.3;
                    text-align: center;
                }
                .author-follows {
                    font-size: 14px;
                    line-height: 1.3;
                    text-align: center;
                }
            }
            .btn-follow {
                width: 95px;
                height: 44px;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
            }
            .co-authors {
                .co-author-label,
                .co-author-name {
                    text-align: center;
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
            .socials {
                margin-top: 0px;
                margin-left: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
    .story-banner {
        .banner-wrapper {
            padding-top: 35px;
            padding-bottom: 45px;
            .img-hand-book {
                right: 60px;
            }
        }
    }
    .story-template {
        .btn-story-edit {
            width: 34px;
            height: 34px;
            border-radius: 34px;
            img {
                width: calc(100% - 14px);
            }
        }
    }
}

@include media-breakpoint-down(md) { // 768px
    .profile-dropdown {
        width: auto;
        margin-left: auto;
        margin-right: 30px;
        .dropdown-toggle {
            .avatar {
                width: 32px;
                height: 32px;
                .badge {
                    width: 20px;
                    height: 20px;
                    font-size: 12px;
                    line-height: 20px;
                    top: -2px;
                    left: -12px;
                }
            }
        }
        .dropdown-menu {
            width: 120px;
            top: 15px !important;
            left: 50% !important;
            transform: translateX(-50%);
            padding: 35px 15px;
            .dropdown-item {
                font-size: 12px;
            }
        }
    }
    .profile-banner {
        .btn-profile-edit {
            width: 84px;
            height: 32px;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #141A46;
        }
        .author-profile {
            justify-content: center;
            .author-img {
                width: 90px;
                height: 90px;
                margin-right: 24px;
            }
            .author-info {
                .author-name {
                    font-size: 18px;
                    line-height: 1.3;
                    margin-bottom: 0px;
                }
                .author-follows {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
        }
        .follow-buttons {
            justify-content: center;
            margin-top: 2px;
            .btn-follow {
                padding: 0px;
                width: 67px;
                height: 31px;
                font-family: "Libre Franklin";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
            }
        }
    }
    .co-author-profile-banner {
        background: rgba(229, 225, 214, 0.3);
        width: 100%;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
        .author-profile {
            display: flex;
            align-items: center;
            justify-content: center;
            .author-img {
                width: 90px;
                height: 90px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .btn-follow {
                width: 67px;
                height: 31px;
                font-family: "Libre Franklin";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 1.3;
            }
        }
    }
    .story-banner {
        .banner-wrapper {
            .img-hand-book {
                right: 60px;
            }
        }
    }
    .story-pagination {
        .btn {
            font-size: 18px;
            padding: 6px 18px;
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .headline {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        color: #141A46;
    }
    .subline {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #141A46;
    }
    .profile-dropdown {
        margin-right: 40px;
    }
    .profile-banner {
        .author-profile {
            justify-content: center;
            .author-img {
                width: 70px;
                height: 70px;
                margin-right: 10px;
            }
            .author-info {
                .author-name {
                    font-size: 16px;
                    margin-bottom: 0px;
                }
                .author-follows {
                    font-size: 13px;
                }
            }
        }
        .follow-buttons {
            justify-content: center;
            .btn-follow {
                padding: 0px;
                width: 67px;
                height: 31px;
                font-family: "Libre Franklin";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 30px;
            }
        }
    }
    .co-author-profile-banner {
        background: rgba(229, 225, 214, 0.3);
        width: 100%;
        height: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        .author-profile {
            display: flex;
            align-items: center;
            justify-content: center;
            .author-img {
                width: 70px;
                height: 70px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .author-info {
                .author-name {
                    font-size: 16px;
                }
                .author-follows {
                    font-size: 13px;
                }
            }
        }
    }
    .story-banner {
        .banner-wrapper {
            padding-top: 30px;
            padding-bottom: 30px;
            .img-hand-book {
                right: 35px;
            }
        }
    }
    .story-pagination {
        .btn {
            font-size: 14px;
            padding: 3px 8px;
            margin: 0 8px;
        }
    }
    .story-template {
        .btn-story-edit {
            width: 28px;
            height: 28px;
            border-radius: 28px;
            img {
                width: calc(100% - 10px);
            }
        }
    }
}

.story-tags {
    display: inline-block;
}
.story-tag {
    width: auto;
    height: 29px;
    background: #E9E9E9;
    border-radius: 20px;
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #353535;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    padding: 0 10px;
    margin-top: 8px;
    cursor: pointer;
    &.active {
        background: #ABACB8;
    }
}