@import "~bootstrap/scss/bootstrap";

.section-stories {
    .story-list {
        padding-top: 20px;
        padding-bottom: 60px;
        .story-item {
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            .story-img {
                position: relative;
                width: 100%;
                overflow: hidden;
                margin-bottom: 10px;
                padding-top: 75%;
                background-color: #D9D9D9;
                background-image: url('../../assets/images/icons/share.svg');
                background-position: center center;
                background-repeat: no-repeat;
                img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .story-content {
                padding-left: 30px;
                .story-author {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #ABACB8;
                }
                .story-title {
                    font-family: 'Lora';
                    font-style: italic;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 38px;
                    color: #141A46;
                }
                .story-detail {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    color: #141A46;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) { // 1200px
    
}

@include media-breakpoint-down(lg) { // 992px
    
    .section-stories {
        .story-list {
            .story-item {
                .story-content {
                    .story-title {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(md) { // 768px
    .section-stories {
        .story-list {
            .story-item {
                .story-content {
                    padding-left: 0px;
                    .story-title {
                        font-size: 28px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .section-stories {
        .story-list {
            padding-top: 0px;
            padding-bottom: 30px;
            .story-item {
                .story-content {
                    .story-title {
                        font-size: 28px;
                    }
                    .story-detail {
                        max-height: 112px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
