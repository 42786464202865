@import "~bootstrap/scss/bootstrap";

.section-header {
    padding: 50px 0;
    .buttons-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-write {
            width: 100px;
            height: 53px;
            background: #DB805E;
            border-radius: 8px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
        }
        .btn-import {
            margin-left: 12px;
            width: 100px;
            height: 53px;
            background: #ABACB8;
            border-radius: 8px;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

.story-tabs {
    .nav-item {
        .nav-link {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 42px;
            color: #ABACB8;
            padding: 0;
            margin-left: 50px;
            &.active, &:hover {
                background: transparent;
                border-color: transparent !important;
                border-bottom: 3px solid #E1805E !important;
                color: #141A46;
            }
        }
        &:first-child {
            .nav-link {
                margin-left: 0px;
            }
        }
    }
}

.story-date {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 42px;
    color: #141A46;
}

.btn-share {
    padding: 0px !important;
    &:active, &:focus, &:hover, &:focus-visible {
        border-color: transparent !important;
        outline: none !important;
    }
}

.action-dropdown {
    .dropdown-toggle {
        padding-left: 0;
        padding-right: 0;
        &::after {
            display: none;
        }
        &:active, &:focus, &:hover, &:focus-visible {
            border-color: transparent !important;
            outline: none !important;
        }
        &.show {
            border-color: transparent !important;
            outline: none !important;
            &:active, &:focus, &:hover, &:focus-visible {
                border-color: transparent !important;
                outline: none !important;
            }
        }
    }
    .dropdown-menu {
        top: -10px;
        background: #FFFFFF;
        box-shadow: -4px 4px 6px rgba(197, 197, 197, 0.5);
        border-radius: 0px;
        border: none;
        padding: 19px 26px;
        min-width: 189px;
        .dropdown-item {
            padding: 1px 0;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #141A46;
            &:hover {
                background-color: transparent;
                font-weight: 700;
            }
        }
        .dropdown-divider {
            border-top: 2px dotted #abacb8;
        }
    }
}

.import-modal, 
.login-prompt-modal {
    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: 655px;
        width: 100%;
    }
    .modal-header {
        padding: 60px 80px;
    }
    .modal-title {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
        line-height: 55px;
        color: #141A46;
    }
    .modal-footer {
        padding: 80px 0;
    }
    .import-options {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .btn-import-from-google,
        .btn-import-from-dropbox,
        .btn-import-from-upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #141A46;
            &:hover, &:active, &:focus, &:focus-visible {
                background: transparent !important;
                border: none !important;
                color: #141A46 !important;
            }
            img {
                max-height: 100px;
                margin-bottom: 15px;
            }
        }
    }
    .btn-exit, .btn-login {
        width: 94px;
        height: 52px;
        background: #DB805E;
        border-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-signup {
        width: 112px;
        height: 52px;
        background: #DB805E;
        border-radius: 8px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.story-action-modal {
    &.lg-modal {
        .modal-dialog {
            max-width: 740px;
        }
    }
    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 655px;
        width: 100%;
        height: 100%;
        .modal-header {
            padding: 40px 70px 20px;
            .modal-title {
                font-family: 'Lora';
                font-style: normal;
                font-weight: 600;
                font-size: 46px;
                line-height: 55px;
                color: #141A46;
            }
            .btn-settings {
                width: 32px;
                height: 32px;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 40px;
                right: 50px;
                background: transparent;
                &:hover, &:active, &:focus, &:focus-visible, &:focus-within {
                    background: transparent !important;
                }
                img {
                    width: 100%;
                }
            }
        }
        .modal-footer {
            padding: 20px 70px 50px;
            .btn-copy-link {
                width: 133px;
                height: 53px;
                background: #FFFFFF;
                border: 2px solid #D9D9D9;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #141A46;
                &:hover, &:focus, &:focus-within, &:focus-visible {
                    background-color: #141A46;
                    border-color: #141A46;
                    color: #FFFFFF;
                    outline: none;
                    box-shadow: none;
                }
                &:active {
                    background-color: rgba(#141A46, 0.85);
                    border-color: #141A46;
                    color: #FFFFFF;
                }
            }
            .btn-close-modal {
                width: 100px;
                height: 53px;
                background: #DB805E;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #FFFFFF;
            }
        }
        .modal-body {
            padding: 0 70px;
            .modal-subline {
                font-family: 'Libre Franklin';
                font-style: italic;
                font-weight: 400;
                font-size: 24px;
                line-height: 38px;
                color: #141A46;
                margin-bottom: 10px;
            }
            .form-group {
                margin-bottom: 15px;
                label {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 28px;
                    color: #ABACB8;
                    margin-bottom: 5px;
                }
                .form-control {
                    background: #E9E9E9;
                    border-radius: 20px;
                    outline: none;
                    box-shadow: none;
                    border: none;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: 0.03em;
                    color: #353535;
                    padding: 0 20px;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }
                }
            }
            .share-form-group {
                display: flex;
                flex-direction: row;
                .share-role {
                    width: 125px;
                    margin-left: 10px;
                }
            }
            .modal-subtitle {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: #141A46;
                margin-bottom: 10px;
            }
            .user-item {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .user-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .user-info {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.03em;
                    padding-left: 9px;
                    .user-name {
                        color: #353535;
                    }
                    .user-email {
                        color: #ABACB8;
                    }
                }
            }
            .access-option {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.03em;
                color: #353535;
                .access-option-dropdown {
                    .dropdown-toggle {
                        padding: 0px;
                        outline: none !important;
                        box-shadow: none !important;
                        border: none !important;
                        &::after {
                            border-top-color: #ABACB8;
                        }
                        &:focus, &:active, &:hover, &:focus-visible {
                            outline: none !important;
                            box-shadow: none !important;
                            border: none !important;
                        }
                        &.show {
                            outline: none !important;
                            box-shadow: none !important;
                            border: none !important;
                            &:focus, &:active, &:hover, &:focus-visible {
                                outline: none !important;
                                box-shadow: none !important;
                                border: none !important;
                            }
                        }
                    }
                    .dropdown-menu {
                        box-shadow: -4px 4px 6px rgba(197, 197, 197, 0.5);
                        border: none;
                        border-radius: 2px;
                    }
                }
                p {
                    color: #ABACB8;
                }
            }
            .part-list {
                margin-top: 35px;
                max-height: 550px;
                // overflow-y: auto;
                .part-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 22px;
                    .part-preview {
                        width: 84px;
                        height: 69px;
                        background: #D9D9D9;
                    }
                    .part-info {
                        padding-left: 20px;
                        .part-headline {
                            font-family: 'Libre Franklin';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 38px;
                            color: #141A46;
                        }
                        .part-subline {
                            font-family: 'Libre Franklin';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 28px;
                            color: #141A46;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) { // 992px
    .section-header {
        .buttons-wrapper {
            .btn-write {
                font-size: 18px;
                font-weight: 600;
                height: 44px;
                line-height: 30px;
                width: 95px;
            }
            .btn-import {
                margin-left: 8px;
                font-size: 18px;
                font-weight: 600;
                height: 44px;
                line-height: 30px;
                margin-left: 18px;
                width: 95px;
            }
        }
    }
}

@include media-breakpoint-down(md) { // 768px
    .section-header {
        .buttons-wrapper {
            .btn-write {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                height: 31px;
                line-height: 30px;
                padding: 0;
                width: 67px;
            }
            .btn-import {
                margin-left: 6px;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                height: 31px;
                line-height: 30px;
                padding: 0;
                width: 67px;
            }
        }
    }
    .import-modal, 
    .login-prompt-modal {
        .modal-dialog {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            max-width: 545px;
            width: 100%;
        }
        .modal-header {
            padding: 40px 60px 20px;
        }
        .modal-title {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 45px;
            color: #141A46;
        }
        .modal-footer {
            padding: 40px 0 60px;
        }
        .import-options {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .btn-import-from-google,
            .btn-import-from-dropbox,
            .btn-import-from-upload {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: none;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                color: #141A46;
                &:hover, &:active, &:focus, &:focus-visible {
                    background: transparent !important;
                    border: none !important;
                }
                img {
                    max-height: 80px;
                    margin-bottom: 10px;
                }
            }
        }
        .btn-exit, .btn-login {
            width: 80px;
            height: 42px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .btn-signup {
            width: 90px;
            height: 42px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .story-action-modal {
        &.lg-modal {
            .modal-dialog {
                max-width: calc(100% - 20px);
            }
        }
        .modal-dialog {
            max-width: calc(100% - 20px);
            .modal-header {
                padding: 30px 50px 20px;
                .modal-title {
                    font-size: 36px;
                    line-height: 45px;
                }
                .btn-settings {
                    width: 32px;
                    height: 32px;
                    top: 30px;
                    right: 40px;
                }
            }
            .modal-footer {
                padding: 20px 50px 40px;
                .btn-copy-link {
                    width: 110px;
                    height: 42px;
                    background: #FFFFFF;
                    border: 2px solid #D9D9D9;
                    border-radius: 8px;
                    font-size: 16px;
                    line-height: 28px;
                }
                .btn-close-modal {
                    width: 80px;
                    height: 42px;
                    border-radius: 8px;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            .modal-body {
                padding: 0 50px;
                .modal-subline {
                    font-size: 22px;
                    line-height: 34px;
                    margin-bottom: 10px;
                }
                .form-group {
                    margin-bottom: 15px;
                    label {
                        font-size: 14px;
                        line-height: 28px;
                        margin-bottom: 5px;
                    }
                    .form-control {
                        font-size: 14px;
                        line-height: 28px;
                        padding: 0 20px;
                        height: 42px;
                    }
                }
                .modal-subtitle {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
                .user-item {
                    margin-bottom: 15px;
                    .user-avatar {
                        min-width: 30px;
                        width: 30px;
                        height: 30px;
                    }
                    .user-info {
                        font-size: 14px;
                        line-height: 20px;
                        padding-left: 9px;
                    }
                }
                .access-option {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.03em;
                    color: #353535;
                    p {
                        color: #ABACB8;
                    }
                }
                .part-list {
                    margin-top: 25px;
                    max-height: 535px;
                    overflow-y: auto;
                    .part-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 18px;
                        .part-preview {
                            min-width: 74px;
                            width: 74px;
                            height: 59px;
                        }
                        .part-info {
                            padding-left: 20px;
                            .part-headline {
                                font-size: 18px;
                                line-height: 34px;
                                color: #141A46;
                            }
                            .part-subline {
                                font-size: 13px;
                                line-height: 1.4;
                                color: #141A46;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .section-header {
        .headline {
            width: 100%;
            text-align: center;
        }
        .buttons-wrapper {
            margin-top: 8px;
            justify-content: center;
        }
    }
    .import-modal, 
    .login-prompt-modal {
        .modal-dialog {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            max-width: calc(100% - 30px);
            width: 100%;
        }
        .modal-header {
            padding: 20px 30px 10px;
        }
        .modal-title {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: #141A46;
        }
        .modal-footer {
            padding: 20px 0 40px;
        }
        .import-options {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .btn-import-from-google,
            .btn-import-from-dropbox,
            .btn-import-from-upload {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: none;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #141A46;
                &:hover, &:active, &:focus, &:focus-visible {
                    background: transparent !important;
                    border: none !important;
                }
                img {
                    max-height: 60px;
                    margin-bottom: 10px;
                }
            }
        }
        .btn-exit, .btn-login {
            width: 67px;
            height: 31px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 28px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .btn-signup {
            width: 75px;
            height: 31px;
            background: #DB805E;
            border-radius: 8px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 28px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .story-action-modal {
        &.lg-modal {
            .modal-dialog {
                max-width: calc(100% - 15px);
            }
        }
        .modal-dialog {
            max-width: calc(100% - 15px);
            .modal-header {
                padding: 20px 30px 20px;
                .modal-title {
                    font-size: 28px;
                    line-height: 36px;
                }
                .btn-settings {
                    width: 28px;
                    height: 28px;
                    top: 20px;
                    right: 30px;
                }
            }
            .modal-footer {
                padding: 20px 30px 20px;
                .btn-copy-link {
                    width: 90px;
                    height: 31px;
                    background: #FFFFFF;
                    border: 2px solid #D9D9D9;
                    border-radius: 8px;
                    font-size: 12px;
                    line-height: 28px;
                }
                .btn-close-modal {
                    width: 70px;
                    height: 31px;
                    border-radius: 8px;
                    font-size: 12px;
                    line-height: 28px;
                }
            }
            .modal-body {
                padding: 0 30px;
                .modal-subline {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
                .form-group {
                    margin-bottom: 15px;
                    label {
                        font-size: 14px;
                        line-height: 28px;
                        margin-bottom: 5px;
                    }
                    .form-control {
                        font-size: 14px;
                        line-height: 28px;
                        padding: 0 20px;
                        height: 42px;
                    }
                }
                .share-form-group {
                    display: flex;
                    flex-direction: column;
                    .share-role {
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 0px;
                    }
                }
                .modal-subtitle {
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 8px;
                }
                .user-item {
                    margin-bottom: 15px;
                    .user-avatar {
                        width: 30px;
                        height: 30px;
                    }
                    .user-info {
                        font-size: 14px;
                        line-height: 20px;
                        padding-left: 9px;
                    }
                }
                .access-option {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: 0.03em;
                    color: #353535;
                    p {
                        color: #ABACB8;
                    }
                }
                .part-list {
                    margin-top: 20px;
                    max-height: 535px;
                    overflow-y: auto;
                    .part-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 18px;
                        .part-preview {
                            min-width: 64px;
                            width: 64px;
                            height: 49px;
                        }
                        .part-info {
                            padding-left: 18px;
                            .part-headline {
                                font-size: 16px;
                                line-height: 30px;
                                color: #141A46;
                            }
                            .part-subline {
                                font-size: 12px;
                                line-height: 1.4;
                                color: #141A46;
                            }
                        }
                    }
                }
            }
        }
    }
}