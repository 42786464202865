@import "~bootstrap/scss/bootstrap";

.section-stories {
    .search-form {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .search-icon {
            position: absolute;
            left: 0px;
            background-image: url('../../assets/images/icons/magnifyer.svg');
            width: 21px;
            height: 21px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
        .search-input {
            width: 100%;
            height: 98px;
            border-left: none;
            border-right: none;
            border-top: 2px solid #D9D9D9;
            border-bottom: 2px solid #D9D9D9;
            font-family: 'Lora';
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            color: #141A46;
            padding-left: 34px;
            &:focus, &:focus-visible {
                border-left: none;
                border-right: none;
                border-top: 2px solid #D9D9D9;
                border-bottom: 2px solid #D9D9D9;
                outline: none;
            }
        }
    }
    .user-list {
        padding-top: 20px;
        padding-bottom: 60px;
        .user-list-item {
            display: flex;
            .user-photo {
                max-width: 179px;
                height: 179px;
                width: 18%;
                flex-basis: 100%;
                border-radius: 50%;
                overflow: hidden;
                .user-photo-wrapper {
                    position: relative;
                    width: 100%;
                    padding-top: 100%;
                    img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }
            }
            .user-info {
                padding-left: 42px;
                .user-name {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 28px;
                    color: #141A46;
                }
                .user-followers {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #141A46;
                }
                .user-about {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    color: #141A46;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .section-stories {
        .user-list {
            .user-list-item {
                flex-direction: column;
                .user-photo {
                    width: 30%;
                    margin-left: auto;
                    margin-right: auto;
                }
                .user-info {
                    padding-left: 0px;
                    text-align: center;
                }
            }
        }
    }
}