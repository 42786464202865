.DraftEditor-root {
  height: unset !important;

  video {
    width: 80%;
  }

  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      min-height: 650px;
    }
  }
}

// inline toolbar customize style
.tukdd6b {
  z-index: 101;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  overflow: hidden;
}

// button style
.b181v2oy {
  background-color: #141a46;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  margin: 0;
  height: 100%;
  padding: 12px 5px 8px 5px;
  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background-color: #373f7b;
  }
  svg {
    color: white;
    fill: white;
    height: 24px;
  }
}

// active button style
.a9immln {
  background-color: #373f7b;
}

// font size
.FONT_SIZE_8 {
  font-size: 8px;
}
.FONT_SIZE_10 {
  font-size: 10px;
}
.FONT_SIZE_12 {
  font-size: 12px;
}
.FONT_SIZE_14 {
  font-size: 14px;
}
.FONT_SIZE_16 {
  font-size: 16px;
}
.FONT_SIZE_18 {
  font-size: 18px;
}
.FONT_SIZE_20 {
  font-size: 20px;
}
.FONT_SIZE_22 {
  font-size: 22px;
}
.FONT_SIZE_24 {
  font-size: 24px;
}
.FONT_SIZE_26 {
  font-size: 26px;
}
.FONT_SIZE_28 {
  font-size: 28px;
}
.FONT_SIZE_30 {
  font-size: 30px;
}
.FONT_SIZE_32 {
  font-size: 32px;
}
.FONT_SIZE_34 {
  font-size: 34px;
}
.FONT_SIZE_36 {
  font-size: 36px;
}
.FONT_SIZE_38 {
  font-size: 38px;
}
.FONT_SIZE_40 {
  font-size: 40px;
}
.FONT_SIZE_42 {
  font-size: 42px;
}
.FONT_SIZE_44 {
  font-size: 44px;
}
.FONT_SIZE_46 {
  font-size: 46px;
}
.FONT_SIZE_48 {
  font-size: 48px;
}

// .RichEditor-editor {
//   cursor: text;
//   font-size: 16px;
//   padding: 10px;
//   height: 200px;
//   overflow: auto;
// }

// .DraftEditor-editorContainer {
//   position: relative;
// }

// .public-DraftEditorPlaceholder-root {
//   position: absolute;
// }

// .public-DraftEditorPlaceholder-inner {
//   color: #ccc;
// }

// .RichEditor-hidePlaceholder
//   .DraftEditor-root
//   .public-DraftEditorPlaceholder-root {
//   display: none;
// }

// .align-right div {
//   text-align: right;
// }
// .align-center div {
//   text-align: center;
// }
// .align-left div {
//   text-align: left;
// }

// .align-justify div {
//   text-align: justify;
// }

/* .align-justify div:after {
    content: "";
    display: inline-block;
    width: 100%;
  } */

// .e8k2yoa {
//   margin: 0;
//   padding: 0;
//   width: 1em !important;
//   height: 1em !important;
//   line-height: 1.2em;
//   font-size: 1.5em;
//   color: #888;
//   background: #fff;
//   border: none !important;
//   border-radius: 1.5em;
//   cursor: pointer;
//   margin-top: 0.2em !important;
// }

// .e13wqaj6 {
//   margin: 0;
//   padding: 0;
//   width: 1em !important;
//   height: 1em !important;
//   box-sizing: border-box;
//   line-height: 1.2em;
//   font-size: 1.5em;
//   color: #888;
//   background: #fff;
//   border: none !important;
//   border-radius: 1.5em;
//   cursor: pointer;
//   background: #ededed;
//   margin-top: 0.2em !important;
// }

// draft text-alignment style
// .draftLeft :global(.public-DraftStyleDefault-ltr) {
//   text-align: left;
// }

// .draftRight :global(.public-DraftStyleDefault-ltr) {
//   text-align: right;
// }

// .draftCenter :global(.public-DraftStyleDefault-ltr) {
//   text-align: center;
// }

.image-caption-wrapper {
  position: relative;
  margin-bottom: 1em;

  img {
    max-width: 100%;
    height: auto;
  }

  .image-caption,
  .image-caption-input {
    width: 100%;
    padding: 5px;
    border: none;
    border-top: 1px solid #ccc;
    font-size: 14px;
    font-style: italic;
    color: #666;
    background-color: #f8f8f8;
  }

  .image-caption {
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .image-caption-input {
    &:focus {
      outline: none;
      background-color: #fff;
    }
  }
}
