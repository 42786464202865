@import "~bootstrap/scss/bootstrap";

.write {
    &.story {
        padding-top: 220px;
        .write-navbar {
            height: 100px;
        }
    }
    .scrolled ~ .write-navbar {
        position: fixed;
        top: 93px;
        height: 100px;
        box-shadow: 0 2px 5px 0 rgba(71,52,130,.1);
    }
    .navbar {
        box-shadow: none !important;
    }
    .write-navbar {
        position: absolute;
        top: 123px;
        left: 0px;
        width: 100%;
        height: 166px;
        background: #fff;
        z-index: 990;
        display: flex;
        align-items: center;
        transition: top 0.15s, box-shadow 0.15s;
        &.scrolled {
            position: fixed;
            top: 0px;
            height: 100px;
            box-shadow: 0 2px 5px 0 rgba(71,52,130,.1);
        }
        .navbar-wrapper {
            display: flex;
            align-items: center;
            .add-chapter-dropdown {
                position: relative;
                .add-chapter-toggle {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #E1805E;
                    z-index: 10;
                    &::after {
                        display: none;
                    }
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 5px;
                    }
                }
                .overlay-bg {
                    position: fixed;
                    top: 0;
                    left: 0;
                    background: rgba(117, 117, 117, 0.8);
                    opacity: 0.5;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                .dropdown-menu {
                    position: absolute;
                    left: 185px;
                    top: 0px;
                    padding: 0px;
                    border: none;
                    z-index: 10;
                    .overlay-bg {
                        position: fixed;
                        background: rgba(117, 117, 117, 0.8);
                        opacity: 0.5;
                        width: 100%;
                        height: 100%;
                    }
                    .dropdown-list {
                        position: relative;
                        border-radius: 8px;
                        background: #FFFFFF;
                        padding: 15px 20px 20px 20px;
                        z-index: 10;
                        min-width: 222px;
                    }
                    .dropdown-item {
                        padding: 0;
                        display: flex;
                        align-items: center;
                        min-width: 173px;
                        &:hover, &:focus, &:active, &:focus-visible {
                            outline: none;
                            background: transparent;
                            .part-info {
                                text-shadow: 0px 0px 0.5px #353535;
                            }
                        }
                        .part-info {
                            display: flex;
                            flex-direction: column;
                            font-family: 'Libre Franklin';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #353535;
                        }
                        .part-check {
                            position: relative;
                            display: block;
                            width: 24px;
                            height: 24px;
                            background-image: url('../../assets/images/icons/ic_check_gray.svg');
                            background-size: 100% 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
            .author-profile {
                display: flex;
                align-items: center;
                .btn-back {
                    height: 100%;
                    border: none;
                    &:active, &:active, &:focus, &:visited{
                        outline: none;
                        border: none;
                    }
                }
                .author-avatar {
                    min-width: 70px;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .story-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 40px;
                    .story-title {
                        font-family: 'Libre Franklin';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #141A46;
                    }
                    .story-mode {
                        font-family: 'Libre Franklin';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 28px;
                        color: #ABACB8;
                    }
                }
            }
            .button-group {
                display: flex;
                align-items: center;
                .btn-skip {
                    margin-left: 10px;
                }
                .btn-publish {
                    width: 125px;
                    height: 53px;
                    background: #DB805E;
                    border-radius: 8px;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .btn-preview {
                    width: 122px;
                    height: 53px;
                    background: #FFFFFF;
                    border: 2px solid #D9D9D9;
                    border-radius: 8px;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    color: #141A46;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .write-dropdown {
        .dropdown-toggle {
            background: transparent;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #141A46;
            img {
                width: 24px;
                height: 24px;
            }
        }
        .dropdown-menu {
            left: 0px;
            width: 100%;
            background: transparent;
            border-radius: 0px;
            border: 0;
            padding: 5px 10px 5px 15px;
            z-index: 99;
            .dropdown-item {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #141A46;
                &:hover, &:active, &:focus, &:focus-visible {
                    background: transparent;
                    font-weight: 700;
                    outline: none;
                }
            }
        }
        .dropdown-toggle {
            &::after {
                content: "";
                width: 10px;
                height: 5px;
                border: none;
                background-image: url('../../assets/images/icons/ic_down_gray.svg');
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    .story-form {
        width: 100%;
        padding: 140px 0;
        .headline {
            margin-top: 40px;
            margin-bottom: 60px;
        }
        .form-group {
            margin-bottom: 35px;
            label {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 28px;
                color: #141A46;
                margin-bottom: 15px;
                span {
                    font-weight: 400;
                }
            }
            .form-control {
                background: #FFFFFF;
                box-shadow: inset 0px 2px 6px #D2D2D2;
                height: 68px;
                outline: none;
                border: none;
                border-radius: 0;
                display: flex;
                align-items: center;
                padding: 20px 35px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                &:focus, &:focus-visible, &:focus-within {
                    outline: none;
                    border: none;
                    border-radius: 0;
                }
                &::placeholder,
                &::-moz-placeholder,
                &::-webkit-input-placeholder {
                    color: #8A8A8A;
                }
            }
            textarea {
                height: auto !important;
            }
            .tags {
                display: block;
                .tag {
                    display: inline-flex;
                    align-items: center;
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 28px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    color: #8A8A8A;
                    height: 33px;
                    border-radius: 20px;
                    padding: 0 10px;
                    background-color: #FFFFFF;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            .input-tag {
                position: relative;
                display: inline-flex;
                align-items: center;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #8A8A8A;
                span {
                    position: absolute;
                    left: 10px;
                }
                input {
                    padding-left: 22px;
                    padding-right: 10px;
                    width: 120px;
                    height: 33px;
                    background: #FFFFFF;
                    box-shadow: inset 0px 3px 3px rgba(210, 210, 210, 0.8);
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    color: #8A8A8A;
                    border: none;
                    outline: none;
                    &:focus {
                        border: none;
                        outline: none;
                    }
                }
            }
            .switch-label {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                color: #8A8A8A;
            }
            .form-switch {
                display: flex;
                align-items: center;
                .form-check-input {
                    width: 66px;
                    height: 33px;
                    margin-top: 0px;
                    box-shadow: inset 0px 2px 6px #D2D2D2;
                    border: none;
                    &:active, &:focus, &:focus-visible, &:focus-within {
                        box-shadow: inset 0px 2px 6px #D2D2D2;
                    }
                    &:focus {
                        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e")
                    }
                    &:checked {
                        background-color: #DB805E;
                        // background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e")
                    }
                }
                label {
                    font-family: 'Libre Franklin';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                    color: #8A8A8A;
                    margin-left: 9px;
                    margin-bottom: 0px;
                }
            }
            .btn-upload {
                width: 290px;
                height: 240px;
                background: #D9D9D9;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 78px;
                    user-select: none;
                    pointer-events: none;
                }
            }
        }
        .btn-done {
            margin-top: 65px;
        }
    }
    .story-note {
        font-size: 20px;
        .form-control, .form-textarea {
            font-size: 20px;
            padding: 10px 20px;
        }
    }
    .btn-primary {
        width: 100px;
        height: 53px;
        background: #DB805E;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
    }
    .btn-secondary {
        width: 133px;
        height: 53px;
        background: #FFFFFF;
        border: 2px solid #D9D9D9;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #141A46;
        &:hover, &:active, &:focus, &:focus-visible, &:focus-within {
            background-color: #141a46;
            border-color: #141a46;
            color: #FFFFFF;
        }
    }
    .rdw-editor {
        .rdw-editor-main {
            background: #FFFFFF;
            box-shadow: inset 0px 2px 6px #D2D2D2;
            min-height: 468px;
            outline: none;
            border: none;
            border-radius: 0;
            padding: 20px 35px;
            font-family: "Libre Franklin";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            &::placeholder,
            &::-moz-placeholder,
            &::-webkit-input-placeholder {
                color: #8A8A8A;
            }
        }
        .rdw-link-modal {
            height: auto;
        }
        .rdw-editor-toolbar, 
        .rdw-option-wrapper, 
        .rdw-dropdown-wrapper {
            background: transparent;
        }
    }
}

@include media-breakpoint-down(xl) { // 1200px
    .write {
        .scrolled ~ .write-navbar {
            position: fixed;
            top: 75px;
            box-shadow: 0 2px 5px 0 rgba(71,52,130,.1);
        }
        .navbar {
            box-shadow: none !important;
        }
        .write-navbar {
            top: 108px;
            height: 120px;
        }
        .story-form {
            padding: 70px 0;
        }
    }
}

@include media-breakpoint-down(md) { // 768px
    .write {
        .scrolled ~ .write-navbar {
            position: fixed;
            top: 62px;
            height: auto;
            box-shadow: 0 2px 5px 0 rgba(71,52,130,.1);
        }
        .navbar {
            box-shadow: none !important;
        }
        .write-navbar {
            top: 92px;
            height: 126px;
            .navbar-wrapper {
                .author-profile {
                    .author-avatar {
                        min-width: 70px;
                        width: 70px;
                        height: 70px;
                        img {
                            width: 100%;
                        }
                    }
                    .story-info {
                        padding-left: 25px;
                        .story-title {
                            font-size: 18px;
                            line-height: 28px;
                        }
                        .story-mode {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                }
                .button-group {
                    .btn-skip {
                        margin-left: 10px;
                    }
                    .btn-publish {
                        width: 90px;
                        height: 31px;
                        font-size: 12px;
                        line-height: 20px;
                        padding: 0;
                    }
                    .btn-preview {
                        width: 90px;
                        height: 31px;
                        font-size: 12px;
                        line-height: 20px;
                        padding: 0;
                    }
                }
            }
        }
        .write-dropdown {
            width: 120px;
            margin-right: 20px;
            .dropdown-menu {
                left: 0px;
                width: 100%;
                background: #FFFFFF;
                box-shadow: -4px 4px 6px rgba(197, 197, 197, 0.5);
                border-radius: 0px;
                border: 0;
                padding: 15px 45px 15px 25px;
                z-index: 99;
                .dropdown-item {
                    padding: 3px 0px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        .story-form {
            padding: 105px 0;
            .headline {
                margin-top: 25px;
                margin-bottom: 40px;
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 12px;
                    span {
                        font-weight: 400;
                    }
                }
                .form-control {
                    height: 58px;
                    padding: 15px 25px;
                    font-size: 20px;
                    line-height: 28px;
                    color: #8A8A8A;
                }
                .input-tag {
                    font-size: 14px;
                    line-height: 28px;
                    span {
                        position: absolute;
                        left: 10px;
                    }
                    input {
                        padding-left: 22px;
                        padding-right: 10px;
                        width: 120px;
                        height: 33px;
                        border-radius: 20px;
                    }
                }
                .form-switch {
                    .form-check-input {
                        width: 60px;
                        height: 30px;
                    }
                    label {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .btn-upload {
                    width: 240px;
                    height: 200px;
                    img {
                        width: 68px;
                    }
                }
            }
            .btn-done {
                margin-top: 45px;
            }
        }
        .btn-primary {
            width: 80px;
            height: 42px;
            border-radius: 8px;
            font-size: 18px;
            line-height: 28px;
        }
        .btn-secondary {
            width: 110px;
            height: 42px;
            border: 2px solid #D9D9D9;
            font-size: 18px;
            line-height: 28px;
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .write {
        .write-navbar {
            height: auto;
            padding: 10px 0 !important;
            .navbar-wrapper {
                .author-profile {
                    width: 100%;
                    .author-avatar {
                        min-width: 70px;
                        width: 70px;
                        height: 70px;
                        img {
                            width: 100%;
                        }
                    }
                    .story-info {
                        padding-left: 25px;
                        .story-title {
                            font-size: 18px;
                            line-height: 28px;
                        }
                        .story-mode {
                            font-size: 14px;
                            line-height: 26px;
                        }
                    }
                }
                .button-group {
                    width: 100%;
                    .btn-skip {
                        margin-left: 10px;
                    }
                }
                .add-chapter-dropdown {
                    .dropdown-menu {
                        top: 25px;
                        left: 90px;
                    }
                }
            }
        }
        .story-form {
            padding: 120px 0;
            .headline {
                margin-top: 20px;
                margin-bottom: 30px;
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 10px;
                    span {
                        font-weight: 400;
                    }
                }
                .form-control {
                    height: 48px;
                    padding: 10px 20px;
                    font-size: 16px;
                    line-height: 20px;
                    color: #8A8A8A;
                }
                .switch-label {
                    font-size: 16px;
                    line-height: 20px;
                }
                .input-tag {
                    font-size: 14px;
                    line-height: 28px;
                    span {
                        position: absolute;
                        left: 10px;
                    }
                    input {
                        padding-left: 22px;
                        padding-right: 10px;
                        width: 120px;
                        height: 33px;
                        border-radius: 20px;
                    }
                }
                .form-switch {
                    .form-check-input {
                        width: 56px;
                        height: 28px;
                    }
                    label {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .btn-upload {
                    width: 200px;
                    height: 160px;
                    img {
                        width: 48px;
                    }
                }
            }
            .btn-done {
                margin-top: 25px;
            }
        }
        .btn-primary {
            width: 70px;
            height: 31px;
            border-radius: 8px;
            font-size: 12px;
            line-height: 22px;
        }
        .btn-secondary {
            width: 100px;
            height: 31px;
            border: 2px solid #D9D9D9;
            font-size: 12px;
            line-height: 22px;
        }
    }
}