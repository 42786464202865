@import "~bootstrap/scss/bootstrap";

.text-primary {
    color: #E1805E;
}

.form-control, .form-textarea {
    background: #FFFFFF !important;
    box-shadow: inset 0px 2px 6px #D2D2D2 !important;
    border-radius: 0px !important;
    outline: none !important;
    border: none !important;
    padding: 12px 25px;
    width: 100%;
    &:hover, &:focus, &:focus-visible, &:active {
        outline: none !important;
        border: none !important;
    }
}

.py-60px {
    padding-top: 60px;
    padding-bottom: 60px;
}

.start {
    .logo {
        max-width: 330px;
        width: 100%;
    }
    .text-card {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 30px 60px;
        width: 100%;
        max-width: 760px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        color: #141A46;
    }
    .start-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 60px;
        .start-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            min-width: 293px;
            text-decoration: none;
            .option-img {
                height: 125px;
                display: flex;
                align-items: flex-end;
            }
            .option-text {
                margin-top: 15px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 38px;
                text-align: center;
                color: #141A46;
            }
        }
    }
    .great-msg, 
    .thanks-msg {
        max-width: 885px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        color: #141A46;
        margin-top: 50px;
        margin-bottom: 40px;
    }
    .thanks-msg {
        max-width: 935px;
    }
    .write-options {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        .write-option {
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 30px;
            max-height: 53px;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 8px;
            &.btn-secondary {
                background: #ABACB8;
                &:hover, &:active {
                    background: #868686;
                }
            }
        }
    }
    .invite-form {
        max-width: 515px;
        width: 100%;
        margin-top: 38px;
        margin-bottom: 100px;
        label {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #141A46;
            margin-bottom: 10px;
        }
        .inline-group {
            display: flex;
            .form-control {
                width: 100%;
                height: 53px;
                display: flex;
                align-items: center;
                padding-left: 25px;
                padding-right: 25px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                color: #8A8A8A;
            }
            .btn-send {
                min-width: 98px;
                height: 53px;
                background: #DB805E;
                border-radius: 8px;
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #FFFFFF;
                margin-left: 25px;
            }
        }
    }
    .msg-sent-img {
        max-width: 315px;
    }
    .msg-sent-img {
        margin-top: 60px;
    }
    .btn-ok {
        padding: 12px 25px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 85px;
    }
    .survey-subline {
        // max-width: 1000px;
        max-width: 550px;
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #141A46;
    }
    .survey-form {
        max-width: 1000px;
        margin-bottom: 60px;
        .form-group {
            margin-top: 60px;
        }
        .form-label {
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 28px;
            color: #141A46;
        }
        .inline-group {
            display: flex;
            align-items: center;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 40px;
            color: #141A46;
            .form-checkbox {
                width: 24px;
                height: 24px;
                background: #FFFFFF;
                border: 2px solid #C0C0C0;
                border-radius: 4px;
                margin-right: 18px;
            }
        }
        .btn-submit {
            margin-top: 60px;
            background: #DB805E;
            border-radius: 8px;
            width: 124px;
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Libre Franklin';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
        }
        .inline-group-sm {
            display: flex;
            align-items: center;
            // justify-content: flex-end;
            max-width: 385px;
            width: 100%;
            margin-bottom: 25px;
            .sub-label {
                font-family: 'Libre Franklin';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 22px;
                text-align: right;
                color: #141A46;
            }
            .form-control {
                height: 68px;
                width: 100%;
            }
        }
        .toggle-show-switch {
            margin-top: 12px;
        }
    }
}

@include media-breakpoint-down(lg) { // 992px
    .start {
        .text-card {
            padding: 20px 40px;
            font-size: 28px;
        }
        .great-msg, 
        .thanks-msg {
            font-size: 28px;
        }
        .start-options {
            .start-option {
                min-width: 250px;
            }
        }
        .survey-form {
            .inline-group-sm {
                display: flex;
                align-items: center;
                max-width: 385px;
                width: 100%;
                margin-bottom: 25px;
                .sub-label {
                    font-size: 18px;
                    line-height: 22px;
                }
                .form-control {
                    height: 53px;
                    width: 100%;
                }
            }
            .toggle-show-switch {
                margin-top: 6px;
            }
        }
    }
}

@include media-breakpoint-down(md) { // 768px
    .start {
        .text-card {
            padding: 20px 40px;
            font-size: 28px;
        }
        .start-options {
            .start-option {
                min-width: 155px;
                // width: 155px;
                padding: 0 15px;
                .option-img {
                    img {
                        transform: scale(0.8);
                    }
                }
            }
        }
        .survey-form {
            margin-bottom: 40px;
            .form-group {
                margin-top: 40px;
            }
            .form-label {
                font-size: 24px;
                line-height: 28px;
            }
            .inline-group {
                font-size: 18px;
                line-height: 36px;
                color: #141A46;
                .form-checkbox {
                    width: 22px;
                    height: 22px;
                    background: #FFFFFF;
                    border: 2px solid #C0C0C0;
                    border-radius: 4px;
                    margin-right: 18px;
                }
            }
            .btn-submit {
                margin-top: 40px;
            }
            .inline-group-sm {
                display: flex;
                align-items: center;
                max-width: 385px;
                width: 100%;
                margin-bottom: 15px;
                .sub-label {
                    font-size: 18px;
                    line-height: 22px;
                }
                .form-control {
                    height: 53px;
                    width: 100%;
                }
            }
            .toggle-show-switch {
                max-width: 385px;
                margin-top: 0px;
                margin-bottom: 15px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

@include media-breakpoint-down(sm) { // 576px
    .start {
        .text-card {
            padding: 20px 30px;
            font-size: 24px;
            line-height: 38px;
        }
        .great-msg,
        .thanks-msg {
            font-size: 24px;
        }
        .start-options {
            flex-direction: column;
            .start-option {
                min-width: 250px;
                .option-img {
                    img {
                        transform: scale(1.0);
                    }
                }
            }
        }
        .write-options {
            .write-option {
                font-size: 18px;
                padding: 10px 20px;
                max-height: 48px;
            }
        }
        .survey-form {
            margin-bottom: 20px;
            .form-group {
                margin-top: 20px;
            }
            .form-label {
                font-size: 18px;
                line-height: 22px;
            }
            .inline-group {
                font-size: 16px;
                line-height: 32px;
                color: #141A46;
                .form-checkbox {
                    width: 20px;
                    height: 20px;
                    background: #FFFFFF;
                    border: 2px solid #C0C0C0;
                    border-radius: 4px;
                    margin-right: 16px;
                }
            }
        }
    }
}